import React from 'react';
import './Products.css';

const Products = () => {
  return (
    <div className="AboutPage-main-container">
      <div className="AboutPage-text-container">
        <h1 className="AboutPage-heading AboutPage-gradient-text">Our Product</h1>
        <p className="AboutPage-paragraph">
          Biofanafood produces high-quality raw banana powder using advanced and sustainable methods, preserving the natural flavor and nutritional benefits of fresh bananas. Our gluten-free, vegan, and non-GMO products cater to health-conscious consumers, perfect for smoothies, baking, baby food, and supplements. we prioritize stringent food safety standards to ensure top-tier quality and customer trust. Supporting local farmers and promoting sustainable agriculture is at the heart of our mission, fostering community growth and environmental stewardship. At Biofanafood, we aim to inspire healthier lifestyles through nutritious, natural ingredients while caring for the planet and its people.
        </p>
      </div>
      <div className="AboutPage-img-container">
        <img src="/imgcontant.png" alt="Descriptive" className="AboutPage-image" />
      </div>
    </div>
  );
};

export default Products;
