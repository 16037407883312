// Pages/Contact.js
import React from 'react';
import Sales from '../Components/Sales/Sales';
import FaqContainer from '../Components/FaqContainer/FaqContainer';
import Footer from '../Components/Footer/Footer';
import Products from '../ServicePages/Products/Products';
const Product = () => {
  return (
    <div>
      <Products/>
      <Sales />
      <FaqContainer />
      <Footer />
    </div>
  );
};

export default Product;